import MissionHistory from "@/features/missions/History/MissionHistory"
import MissionLog from "@/features/missions/Log/MissionLog"
import MissionDetailInfo from "@/features/missions/MissionDetailInfo"
import MissionViewNotes from "@/features/missions/Notes/MissionNotes"
import DepartureTasks from "@/features/tasks/DepartureTasks"
import ServiceStatusDetails from "@/features/vehicles/ServiceStatus/ServiceStatusDetails"
import TruckDetails from "@/features/vehicles/TruckDetails"
import { useEffect, useState } from "react"
import { matchRoutes, useLocation, useNavigate } from "react-router-dom"

interface TabRoute {
  /** what is displayed on the tab link */
  label: string
  /** the routes that the tab is displayed on, /missions | /vehicles | /* */
  matchRoute: "missions" | "vehicles" | "*"
  /** the path that the tab links to */
  pathname: string
  /** the role that the user needs to display the tab */
  role?: string
  permission?: string
  /** the min vehicle gen needed to display the tab */
  minGen?: number
  // TODO -- add a flag to hide tab from users with a specific role(s)
  element: React.ReactNode
}

const tabRoutes: TabRoute[] = [
  {
    label: "Tasks",
    matchRoute: "missions",
    pathname: "tasks",
    element: <DepartureTasks drawerView={false} />,
  },
  {
    label: "Mission Details",
    matchRoute: "missions",
    pathname: "details",
    element: <MissionDetailInfo activeMission={null} />,
  },
  {
    label: "Mission Log",
    matchRoute: "missions",
    pathname: "mission-log",
    element: <MissionLog />,
  },
  {
    label: "Truck",
    matchRoute: "vehicles",
    pathname: "details",
    element: <TruckDetails vehicle={null} mission={null} />,
  },
  {
    label: "Truck",
    matchRoute: "missions",
    pathname: "vehicle",
    element: <TruckDetails vehicle={null} mission={null} />,
  },
  {
    label: "Service Status",
    matchRoute: "vehicles",
    pathname: "service-status",
    element: <ServiceStatusDetails vehicle={null} vehicleConnectivity={null} />,
  },
  {
    label: "Notes",
    matchRoute: "missions",
    pathname: "notes",
    element: <MissionViewNotes missionUUID={null} />,
  },
  {
    label: "History",
    matchRoute: "missions",
    pathname: "history",
    element: <MissionHistory />,
    permission: "GetMissionHistory",
  },
]

// returns available tabs based on the current route
const useGetTabs = ({ drawerView }: { drawerView: boolean } = { drawerView: false }): TabRoute[] => {
  const location = useLocation()
  if (drawerView) {
    return tabRoutes.filter((tab) => !(tab.label === "Truck" && tab.pathname === "details"))
  }

  return tabRoutes.filter((tab) => {
    return location.pathname.includes(tab.matchRoute)
  })
}

// handles defaulting to a tab based on the current route
const useTabHandler = (defaultTab = "details"): string => {
  const location = useLocation()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(defaultTab)

  const tabRoutes = useGetTabs()

  useEffect(() => {
    const routeMatches = matchRoutes(
      [
        {
          path: "/missions/:load_id",
        },
        {
          path: "/vehicles/:vehicle_id",
        },
      ],
      location,
    )

    if (
      routeMatches?.[0]?.route.path === "/missions/:load_id" ||
      routeMatches?.[0]?.route.path === "/vehicles/:vehicle_id"
    ) {
      navigate("details", { replace: true })
      return
    }

    setTabValue(tabRoutes.find((tabRoute) => location.pathname.includes(tabRoute.pathname))?.pathname ?? defaultTab)
  }, [location])

  return tabValue
}

export { tabRoutes, useGetTabs, useTabHandler }
