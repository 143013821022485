import getToken from "@/framework/utils/api/getToken"
import { ActionsApiClient } from "@torc-robotics/mm-actions-client"

const ActionsAPI = new ActionsApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_ACTIONS_API_URL,
  TOKEN: getToken,
}).v1

export const ActionsApiV2 = new ActionsApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_ACTIONS_API_URL,
  TOKEN: getToken,
}).v2

export default ActionsAPI
