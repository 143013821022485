import WarningIcon from "@mui/icons-material/Warning"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import "../css/RouteSelection.scss"

const NoRoutes = ({ vehicleStatus, arrivalLocation, map_version, errorMessage }) => {
  const formatLocation = (point) => {
    return point ? point.toFixed(5) : "0"
  }

  return (
    <Grid container data-testid="no__routes__warning">
      <Grid item>
        <Typography className="mission-route-details__route-subheading">
          <IconButton aria-label="warning" size="small" data-testid="warning">
            <WarningIcon style={{ color: "#D6791C" }} />
          </IconButton>
          {errorMessage}. Verify the following data for accuracy.
        </Typography>
      </Grid>
      <Grid container spacing={4} sx={{ spacing: "margin-left 20px" }}>
        <Grid item xs={12}>
          <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "0px", padding: "20px" }}>
            <Grid container>
              <Grid item xs={3}>
                <Typography className="mission-route-details__route-subheading">Departure lat/lon:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className="mission-route-details__route-subheading">
                  {formatLocation(vehicleStatus?.lat)}/{formatLocation(vehicleStatus?.lon)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="mission-route-details__route-subheading">Arrival lat/lon:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className="mission-route-details__route-subheading">
                  {formatLocation(arrivalLocation?.Destination?.lat)}/
                  {formatLocation(arrivalLocation?.Destination?.lon)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="mission-route-details__route-subheading">Map Version:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className="mission-route-details__route-subheading">{map_version}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NoRoutes
