import { getJwtToken } from "@/framework/Api"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { isEmpty } from "lodash"

const ROUTE_ADVISOR_API_URL = "https://route-advisor.api.cloud.torc.tech/v1"

const useGetRouteAdvisorViasQuery = (currentLocation: string, mapVersion: string) => {
  /* istanbul ignore next */
  const useGetRouteAdvisorVias = useQuery({
    queryKey: ["route-advisor-vias", currentLocation, mapVersion],
    queryFn: async () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (
        await axios.get(
          `${ROUTE_ADVISOR_API_URL}/vias?destination_latlon=${currentLocation}&map_version=${mapVersion}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getJwtToken()}`,
            },
          },
        )
      ).data,
    enabled: !isEmpty(currentLocation) && !isEmpty(mapVersion),
  })

  return useGetRouteAdvisorVias
}

export default useGetRouteAdvisorViasQuery
