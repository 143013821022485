import useGetMapVersionQuery from "@/hooks/query/routes/useGetMapVersionQuery"
import useLastKnownLocation from "@/hooks/query/routes/useLastKnownLocation"
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useMemo, useState } from "react"
import ArrivalSelections from "./ArrivalSelections"
import RouteSelectionMap from "./RouteSelectionMap"
import "../css/RouteSelection.scss"
import useGetRoutesQuery from "@/hooks/query/routes/useGetRoutesQuery"
import NoRoutes from "./NoRoutes"
import VehicleFaultsBanner from "./VehicleFaultsBanner"

const RouteSelection = ({ mission, vehicle, postRoute, action }) => {
  const [arrivalSelection, setArrivalSelection] = useState()
  const [selectedRoute, setSelectedRoute] = useState()
  const [arrivalVias, setArrivalVias] = useState()
  const [triggerQuery, setTriggerQuery] = useState(false)
  const isCompleted = action?.state === "COMPLETED"

  const { data: vehicleStatus } = useLastKnownLocation(vehicle?.vehicle_id)
  const { data: version } = useGetMapVersionQuery(vehicle?.vehicle_id)

  const map_version = version && Object.hasOwn(version, "map_version") ? version["map_version"] : ""
  const truck_gen = vehicle?.gen ? parseFloat(vehicle?.gen) : 2.3

  const {
    data: getRoutes,
    isFetching,
    isError,
    isFetched,
  } = useGetRoutesQuery(
    vehicleStatus,
    arrivalSelection?.Destination,
    map_version,
    arrivalVias,
    !isCompleted && (truck_gen >= 3.0 ? true : triggerQuery),
  )

  const updateArrivalDestination = async (arrival) => {
    setArrivalSelection(arrival)
  }

  const updateArrivalWaypoint = async (vias) => {
    setArrivalVias(vias)
  }

  const handleRouteChange = async (event) => {
    const selected_route_id = event.target.value
    const { estimated_time_minutes, length_miles } =
      getRoutes.data.routes.find((route) => route.route_id === selected_route_id)?.summary_info || {}

    setSelectedRoute(selected_route_id)
    postRoute(selected_route_id, estimated_time_minutes, length_miles)
  }

  useMemo(() => {
    if (isFetched && !isError && getRoutes?.data?.routes) {
      setSelectedRoute(getRoutes.data.routes?.[0]?.["route_id"])
      const { estimated_time_minutes, length_miles } = getRoutes.data.routes?.[0]?.summary_info || {}
      postRoute(getRoutes.data.routes?.[0]?.["route_id"], estimated_time_minutes, length_miles)
    } else {
      setSelectedRoute(null)
    }
    setTriggerQuery(false)
  }, [getRoutes])

  const generateRouteOptions = () => {
    return (
      <>
        <Typography className="mission-route-details__route-subheading">Currently available options:</Typography>
        <Select
          id="route-selection"
          value={selectedRoute}
          data-testid="route-selection"
          inputProps={{ "aria-label": "Without label" }}
          className="mission-route-details__route-options"
          onChange={(e) => handleRouteChange(e)}
        >
          {getRoutes.data.routes.map((option, index) => (
            <MenuItem sx={{ fontSize: "14px" }} key={index} value={option.route_id}>
              {index === 0 ? "Best Route" : `Alternate ${index}`} (Duration:{" "}
              {option.summary_info.estimated_time_minutes} min | Distance: {option.summary_info.length_miles} miles)
            </MenuItem>
          ))}
        </Select>
      </>
    )
  }

  return (
    <>
      {vehicleStatus?.healthy !== undefined && !vehicleStatus?.healthy && !isCompleted ? (
        <VehicleFaultsBanner vehicleId={vehicle.vehicle_id} />
      ) : null}
      <Stack className="route-details" sx={{ padding: 2 }}>
        <Box sx={{ borderBottom: "1px solid #d7d7d7" }}>
          <Typography className="mission-route-details__mission-label">{mission?.load_id}</Typography>
          <Typography className="mission-route-details__truck-name">
            {vehicle?.vehicle_id}
            {vehicle?.name ? `-${vehicle.name}` : null}
          </Typography>
        </Box>

        <Box>
          <Typography className="mission-route-details__heading">MISSION PLAN</Typography>
          <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "10px", padding: "20px" }}>
            <div className="departure-selections">
              <div className="departure-selections__departure_title">
                <Typography className="mission-route-details__subheading">DEPARTURE LOCATION</Typography>
              </div>
              <div className="departure-selections__departure_route">
                <Typography className="mission-route-details__selected-value">
                  {mission?.start_display
                    ? `${mission?.start_display} ${mission?.start_zone ? mission?.start_zone : ""}`
                    : "N/A"}
                </Typography>
              </div>
              {truck_gen < 3.0 ? (
                <>
                  <div className="departure-selections__arrival_title">
                    <Typography className="mission-route-details__subheading">ARRIVAL LOCATION</Typography>
                  </div>
                  <div className="departure-selections__arrival_route">
                    <Typography className="mission-route-details__destination">
                      {mission?.destination_display
                        ? `${mission?.destination_display} ${
                            mission?.destination_zone ? mission?.destination_zone : ""
                          }`
                        : "N/A"}
                    </Typography>
                  </div>
                </>
              ) : null}
            </div>
            {truck_gen >= 3.0 ? (
              <ArrivalSelections
                updateArrivalDestination={updateArrivalDestination}
                updateArrivalWaypoint={updateArrivalWaypoint}
                map_version={map_version}
                current_location={vehicleStatus}
                action={action}
                setTriggerQuery={setTriggerQuery}
              />
            ) : null}
          </Box>
        </Box>
        <Box>
          <Typography className="mission-route-details__heading">ROUTE SELECTION</Typography>
          <Box sx={{ border: "1px solid #d7d7d7", borderRadius: "10px", padding: "20px" }}>
            {truck_gen < 3.0 ? (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography className="mission-route-details__subheading">PLANNED ROUTE</Typography>
                </Grid>
                <Grid item sx={{ opacity: isCompleted ? 0.5 : 1 }}>
                  <Typography className="mission-route-details__selected-route">{mission?.mission_route}</Typography>
                </Grid>
              </Grid>
            ) : !Object.hasOwn(arrivalSelection?.Destination || {}, "lat") ? (
              <Typography className="mission-route-details__route-subheading" sx={{ opacity: isCompleted ? 0.5 : 1 }}>
                {!isCompleted ? "Arrival Location needs to be selected" : action?.formData["selected_route"]}
              </Typography>
            ) : isFetching ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <CircularProgress color="inherit" size={20} />
                <Typography className="mission-route-details__route-subheading">Loading route options</Typography>
              </Box>
            ) : !isError ? (
              <Box className={`route-selection${getRoutes?.data?.routes?.length > 0 ? "__small" : ""}`}>
                {getRoutes?.data?.routes && getRoutes.data?.routes?.length > 0 ? (
                  generateRouteOptions()
                ) : (
                  <NoRoutes
                    vehicleStatus={vehicleStatus}
                    map_version={map_version}
                    arrivalLocation={arrivalSelection}
                    errorMessage={
                      getRoutes?.data?.message
                        ? getRoutes.data.message
                        : !triggerQuery
                          ? "Select all desired options and press the load button to fetch routes"
                          : "No routes found"
                    }
                  />
                )}
                <RouteSelectionMap
                  routeId={selectedRoute}
                  startCoord={[vehicleStatus?.lon, vehicleStatus?.lat]}
                  endCoord={[arrivalSelection?.Destination?.lon, arrivalSelection?.Destination?.lat]}
                />
              </Box>
            ) : (
              <Typography className="mission-route-details__route-subheading">Error loading route options</Typography>
            )}
          </Box>
        </Box>
      </Stack>
    </>
  )
}

export default RouteSelection
