import { useGetMission } from "@/hooks/missionHooks"
import useGetVehicleV2Query from "@/hooks/query/vehicle/useGetVehicleV2Query"
import { Container, LinearProgress, Typography } from "@mui/material"
import { useGetVehicle, useGetVehicleId } from "../../hooks/vehiclesV2Hooks"
import "./TruckDetails.scss"
import TruckTab from "./TruckTab"

const TruckDetails = ({ vehicle, mission }) => {
  const vehicleId = useGetVehicleId() ?? vehicle?.vehicle_id
  const selectedVehicle = useGetVehicle() ?? vehicle
  const selectedMission = useGetMission() ?? mission
  const vehicleDetailsQuery = useGetVehicleV2Query(vehicleId)

  return (
    <Container className="truck-details">
      {vehicleDetailsQuery.isLoading ? (
        <LinearProgress sx={{ m: "1rem" }} />
      ) : vehicleDetailsQuery.isError ? (
        <Typography variant="overline">The vehicle &apos;{vehicleId}&apos; was not found.</Typography>
      ) : (
        selectedVehicle && <TruckTab vehicle={selectedVehicle} mission={selectedMission} />
      )}
      {}
    </Container>
  )
}

export default TruckDetails
