import { getFormattedDate } from "@/framework/Utils"
import { useGetMission } from "@/hooks/missionHooks"
import useGetActionsForMissionQuery from "@/hooks/query/actions/useGetActionsForMissionQuery"
import {
  Drawer,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import _ from "lodash"
import { useContext, useMemo, useState } from "react"
import ActionDashboard from "./ActionDashboard"
import "./DepartureTasks.scss"

/**
 *
 * @param {{
 *    activeMission?: import("@torc-robotics/mm-missions-client").Mission,
 *    drawerView?: boolean
 * }} props
 * @returns
 */
export default function DepartureTasks({ activeMission, drawerView = false }) {
  const supportedActions = ["form", "review", "request", "response", "report"]
  const { displayRole, canReviewTasks, userData } = useContext(UserContext)
  const mission = useGetMission() ?? activeMission
  const { data, isLoading, refetch } = useGetActionsForMissionQuery(mission?.uuid)

  const inProgressTasks = useMemo(() => {
    if (!data?.actions) {
      return []
    } else if (!canReviewTasks(userData)) {
      return data?.actions?.filter(
        (action) => action?.state.toLowerCase() !== "completed" && action?.subtype.toLowerCase() !== "review",
      )
    } else {
      return data?.actions?.filter((action) => action?.state.toLowerCase() !== "completed")
    }
  }, [data])

  const completedTasks = useMemo(() => {
    if (!data?.actions) {
      return []
    } else {
      const completedActions = data?.actions?.filter((action) => action?.state.toLowerCase() === "completed")
      // threw error when only one completed action was returned
      if (completedActions.length === 1) return completedActions
      return completedActions.sort(function (a, b) {
        return new Date(b.last_updated) - new Date(a.last_updated)
      })
    }
  }, [data])

  const [taskFocus, setTaskFocus] = useState(null)

  const onClose = () => {
    refetch()
    setTaskFocus(null)
  }

  /**
   * Handler for clicking on an action, will open the action form if the action is a note.
   * @param {object} action - action object
   */
  const handleActionClick = (action) => {
    let actionType = _.get(action, "type", null)

    if (_.includes(supportedActions, actionType)) {
      setTaskFocus(action)
    } else {
      console.error(`Action type "${actionType}" not supported`)
    }
  }

  const getUserOrRole = (action) => {
    if (action?.state.toLowerCase() === "completed" && _.get(action, "completed_by")) {
      return _.get(action, "completed_by")
    } else {
      let displayRoles = ""

      let roleLen = action?.roles?.length ? action.roles.length : 0

      for (let i = 0; i < roleLen; i++) {
        displayRoles += displayRole(action.roles[i], userData) + " "
      }

      return displayRoles
    }
  }

  const isSmall = useMediaQuery((theme) => theme?.breakpoints?.down("md"))
  const isMedium = useMediaQuery((theme) => theme?.breakpoints?.down("lg"))

  return (
    <div className="table__container">
      {taskFocus && (
        <Drawer
          anchor="right"
          open={taskFocus}
          onClose={onClose}
          sx={{ zIndex: 1201 }}
          PaperProps={{ sx: { width: isSmall ? 1 : isMedium ? 0.75 : 0.5 } }}
          data-testid="task-drawer"
        >
          <ActionDashboard task={taskFocus} onClose={onClose} />
        </Drawer>
      )}
      {isLoading && <LinearProgress />}
      {!isLoading &&
        [inProgressTasks, completedTasks].map((actionGroup, idx) => (
          <div key={idx}>
            <Typography data-testid="tasks-table-label" variant="caption" className="table__label">
              {idx === 0 ? "Tasks" : "Completed Tasks"}
            </Typography>
            <Paper style={{ boxShadow: "none" }}>
              <Table
                data-testid={"tasks-table-" + (idx === 1 ? "complete" : "in_progress")}
                className={"table table--" + (idx === 1 ? "complete" : "in_progress")}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {actionGroup.length ? (
                      <></>
                    ) : (
                      <TableCell className="table__cell--empty">
                        <div className="table__label--empty">
                          <Typography fontSize={"12px"}>
                            There are no {idx === 1 ? "completed" : "pending"} actions.
                          </Typography>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionGroup.map((action) => {
                    const status = action.state.toLowerCase()
                    const statusStyle = status === "completed" ? "complete" : "due-soon"
                    return (
                      <TableRow data-testid={`tasks-table-row-${action.title}`} key={action.uuid}>
                        <TableCell align="left" scope="row" className="table__cell--task">
                          {action.title}
                        </TableCell>
                        <TableCell align="left">
                          {(() => {
                            return getUserOrRole(action)
                          })()}
                        </TableCell>
                        {idx === 1 && (
                          <TableCell className="no-wrap" align="left">
                            {getFormattedDate(action.last_updated)}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          <div
                            className={`table__cell--status table__cell--status-${statusStyle}`}
                            data-testid="task-status-cell"
                          >
                            {action.state}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {!drawerView && (
                            <Link
                              data-testid={`Open ${action.title}`}
                              name={`Open ${action.title}`}
                              variant="contained"
                              size="small"
                              onClick={() => handleActionClick(action)}
                              style={{ float: "right" }}
                              className="task-link"
                            >
                              {action.subtype === "clearance"
                                ? "Start"
                                : action.state === "NOT STARTED"
                                  ? "Start"
                                  : "Open"}
                            </Link>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        ))}
    </div>
  )
}
