import _ from "lodash"
import { DateTime, Duration } from "luxon"

/**
 * Gets UTC date start Unix Timestamp
 *
 * @param {Date} date
 */
export function getUTCStartDateTimeStamp(date) {
  //convert date so criteria reflects how it is saved in Dynamo DB
  //set day start (12 AM in browser time zone)
  date.setHours(0, 0, 0, 0)
  //shift for the UTC offset, so now it is day start in UTC (needed for Dynamo DB date index)
  date = new Date(date - date.getTimezoneOffset() * 60000)
  //get adjusted unix time stamp (drop milliseconds)
  var dateTimeStamp = (date.getTime() / 1000) | 0

  return dateTimeStamp
}

export function getOnTimeStyle(label) {
  switch (label) {
    case "ON_TIME":
      return "is-on-time"
    case "DELAYED":
      return "is-delay"
    case "AT_RISK":
      return "is-at-risk"
    default:
      return "is-on-time"
  }
}

export function formatOnTimeStatus(label) {
  switch (label) {
    case "ON_TIME":
      return "On Time"
    case "DELAYED":
      return "Delay"
    case "AT_RISK":
      return "At Risk"
    default:
      return "On Time"
  }
}

/**
 * Returns the cardinal compass direction converted
 * from a angular degrees-based heading
 *
 * @note degrees is expected to be 0 - 360
 *
 * @ref https://stackoverflow.com/questions/7490660/converting-wind-direction-in-angles-to-text-words
 *
 * @param {number} degrees
 */
export function degToCompass(degrees) {
  const val = Math.floor(normalizedDegrees(degrees) / 22.5 + 0.5)
  const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]
  return arr[val % 16]
}

export function normalizedDegrees(degrees) {
  return (360 + degrees) % 360
}

/**
 * @param {string} date The date to format, in ISO 8601 format, epoch time also supported.
 * @param {string} format - Optional parameter - The specified luxon format.
 * @param {string} invalidValueDefault - Optional Localized value to show when date is invalid - default is null.
 * @returns The formatted time.
 */
export function getFormattedDate(date, format = "LL/dd hh:mm a ZZZZ", invalidValueDefault = "---") {
  if (date == null || date == undefined) {
    return invalidValueDefault
  }
  let d

  if (typeof date === "string") {
    d = DateTime.fromISO(date)
  } else {
    d = DateTime.fromMillis(date)
  }

  if (date && d.isValid) {
    return d.toFormat(format)
  } else {
    return invalidValueDefault
  }
}

export function getTime(date) {
  return `${getFormattedDate(date, "ttt")}`
}

export const MSM_STATE = {
  SCHEDULED: "SCHEDULED",
  PREPARING: "PREPARING",
  REVIEWING: "REVIEWING",
  CLEARED: "CLEARED",
  WAIT_ACCEPT: "WAIT_ACCEPT",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  UNKNOWN: "UNKNOWN",
}

export const MISSION_STATE = {
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
}

/**
 *
 * @param {*} mission
 * @returns
 */
export function displayEta(mission) {
  if (!mission) {
    return ""
  }

  if (mission.state == "IN_PROGRESS") {
    const eta_timestamp = _.get(mission, "eta_datetime")
    return eta_timestamp ? getFormattedDate(eta_timestamp, "ttt") : ""
  } else {
    return ""
  }
}

/**
 * convert seconds to HH:mm format
 * @param {*} remaining_time_seconds
 * @returns HH:mm
 */
export function secondsToHHMM(remaining_time_seconds) {
  if (isNaN(remaining_time_seconds)) {
    return ""
  }

  // calculate hours and minutes
  const hours = Math.floor(remaining_time_seconds / 3600)
  const minutes = Math.floor((remaining_time_seconds % 3600) / 60)

  // format the output as "HH:mm"
  const formattedHours = hours.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")

  return `${formattedHours}:${formattedMinutes}`
}

/**
 * formats seconds to time remaining
 * mm min if less than an hour
 * HH:mm hr if an hour or more
 * @param {*} remaining_time_seconds
 * @returns HH:mm
 */
export function formatSecondsToMMorHHMM(remaining_time_seconds) {
  if (isNaN(remaining_time_seconds)) {
    return ""
  }

  // calculate hours and minutes
  const hours = Math.floor(remaining_time_seconds / 3600)
  const minutes = Math.floor((remaining_time_seconds % 3600) / 60)

  // format the output as "HH:mm"
  const formattedHours = hours.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}hr`
  } else {
    return `${formattedMinutes}min`
  }
}

/**
 * ETA time remaining in HH:mm format
 * @param {number} remainingSeconds
 * @returns String of time remaining in format "HH:mm"
 */
export function formatTimeLeft(remainingSeconds) {
  if (remainingSeconds == null || remainingSeconds == undefined) {
    return "N/A"
  }

  const timeRemaining = Duration.fromMillis(remainingSeconds * 1000)
  return timeRemaining.toFormat("hh:mm")
}

//Returns css color based on level
export function getCriticalityColor(level) {
  switch (level) {
    case "ERROR":
      return "#FA3751"

    case "WARN":
      return "#FA9A37"

    case "OPERATIONAL":
      return "#009AFA"

    default:
      return "#009AFA"
  }
}

//Map Severity to UX text
export function getCriticalitySeverityLabel(level) {
  switch (level) {
    case "ERROR":
      return "Critical"

    case "WARN":
      return "Warning"

    case "OPERATIONAL":
      return "Operational"

    case "INFO":
      return "Info"

    default:
      return "Info"
  }
}
