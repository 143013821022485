import { getJwtToken } from "@/framework/Api"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import axios from "axios"

const ROUTE_ADVISOR_API_URL = "https://route-advisor.api.cloud.torc.tech/v1/"
const MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS = 10
const ROUTE_ADVISOR_WAIT_TIME = 5000

const getRoutesAPICall = async (arrival, origin, map_version, vias) => {
  let location

  try {
    const response = await axios.post(
      `${ROUTE_ADVISOR_API_URL}async/route-requests`,
      JSON.stringify({
        map_version: map_version,
        origin: {
          type: "latlon",
          lat: origin.lat,
          lon: origin.lon,
        },
        destination: {
          type: "latlon",
          lat: arrival.lat,
          lon: arrival.lon,
        },
        vias: vias,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getJwtToken()}`,
        },
        cache: "default",
      },
    )

    location = response.headers["location"]
  } catch (err) {
    return { data: { message: err.message } }
  }

  return await getRoutes(location)
}

export const getRoutes = async (location) => {
  let attempts = 0
  let response

  while (attempts <= MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS) {
    await new Promise((r) => setTimeout(r, ROUTE_ADVISOR_WAIT_TIME))

    try {
      response = await axios.get(location, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getJwtToken()}`,
        },
        cache: "default",
      })
    } catch (err) {
      return { data: { message: err.message } }
    }

    if (response.data.status === "error") {
      return { data: { message: response.data.error_message } }
    } else if (response.data["routes"]) {
      return response
    } else {
      attempts += 1
      console.log("Routes not ready on attempt ", attempts)
      if (attempts >= MAX_ROUTE_ADVISOR_POLLING_ATTEMPTS) {
        throw new Error("Route advisor could not generate a route")
      }
    }
  }
}

const useGetRoutesQuery = (origin, arrival, map_version, vias, enabled) => {
  const viasData = vias ? vias : []
  const routeQuery = useQuery({
    queryKey: ["route-options", origin, viasData, arrival, map_version],
    queryFn: async () => await getRoutesAPICall(arrival, origin, map_version, viasData),
    enabled: enabled,
    placeholderData: keepPreviousData,
  })

  return routeQuery
}

export default useGetRoutesQuery
