import MissionConflictDetails from "@/features/missions/MissionConflictDetails"
import { getStateDisplay } from "@/framework/utils/MissionDisplay"
import { useGetDepartureRequestsWarning } from "@/hooks/missionHooks"
import { useGetVehicle } from "@/hooks/vehiclesV2Hooks"
import { Warning } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ReplyIcon from "@mui/icons-material/Reply"
import WarningIcon from "@mui/icons-material/Warning"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Chip, IconButton, Tooltip, Typography } from "@mui/material"
import Icon from "@mui/material/Icon"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ArrivingIcon from "./icons/ArrivingIcon"
import styles from "./scss/MissionDetailsHeader.module.scss"

const ETA_ARRIVING_TIME = 900 // 15 Min Indicator to notify users that the vehicle is arriving soon

const MissionDetailsHeader = ({
  mission,
  setMissionUUIDFocus,
  hideBackNavigation = false,
  conflicts = null,
  hideCloseIcon = true,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const departureRequests = useGetDepartureRequestsWarning(mission)
  // not sure why, but location.state is becoming undefined after first render, currently using useState to store this...?
  // TODO - investigate cause and fix
  const [locationState] = useState(location.state ?? null)
  const [openDialog, setOpenDialog] = useState(false)
  const vehicle = useGetVehicle(mission?.vehicle_id)

  const missionConflict = conflicts?.[mission?.uuid] ?? null

  const getMissionHealthDisplay = () => {
    if (mission?.health == "ERROR" || mission?.health == "WARN") {
      return (
        <Link
          to={
            mission
              ? `/missions/${encodeURIComponent(mission?.load_id)}/vehicle`
              : `/vehicles/${vehicle?.vehicle_id}/details`
          }
          className="mission-details-header"
        >
          <Chip
            data-testid={mission?.health == "ERROR" ? "error-chip" : "warn-chip"}
            icon={<Warning />}
            label={mission?.vehicle_id ?? vehicle?.vehicle_id}
            sx={mission?.health == "ERROR" ? "error" : "warning"}
            color={mission?.health == "ERROR" ? "error" : "warning"}
            variant="outlined"
          />
        </Link>
      )
    } else {
      return <Typography className={styles["mission-details-header__vehicle-id"]}>{mission?.vehicle_id}</Typography>
    }
  }

  return (
    <div data-testid={`mission-header_${mission?.load_id}`} className={styles["mission-details-header__row"]}>
      {!hideBackNavigation ? (
        <IconButton
          aria-label="back"
          size="small"
          data-testid="back-btn"
          data-dd-action-name="back-btn"
          onClick={() =>
            locationState?.from ? navigate(`/${locationState.from}`) : navigate(`/${location.pathname.split("/")[1]}`)
          }
        >
          <ReplyIcon />
        </IconButton>
      ) : null}
      <Tooltip
        title={
          <div onClick={(e) => e.stopPropagation()}>
            {mission?.load_id}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(mission?.load_id)
              }}
              disableRipple={false}
              sx={{ color: "white" }}
            >
              <ContentCopyIcon sx={{ fontSize: "16px" }} data-testid="toolTip-copy" />
            </IconButton>
          </div>
        }
      >
        <Typography id="header-load-id" data-testid="load-id" className={styles["mission-details-header__load-id"]}>
          {mission?.load_id}
        </Typography>
      </Tooltip>

      <Typography id="header-vehicle-id" className={styles["mission-details-header__vehicle-id"]}>
        {getMissionHealthDisplay()}
      </Typography>
      <Typography
        id="header-state"
        className={styles["mission-details-header__state"]}
        data-testid="mission-state"
        sx={{ marginLeft: "auto" }}
      >
        {getStateDisplay(mission?.state)}
        {missionConflict ? (
          <>
            <MissionConflictDetails
              mission={mission}
              closeHandle={(e) => {
                e.preventDefault()
                setOpenDialog(false)
              }}
              conflicts={missionConflict}
              open={openDialog}
            />
            <IconButton
              data-testid="conflicts-btn"
              onClick={(e) => {
                e.preventDefault()
                setOpenDialog(true)
              }}
            >
              <WarningAmberIcon color={"warning"} />
            </IconButton>
          </>
        ) : null}
        {!hideCloseIcon ? (
          <IconButton
            data-testid="close-btn"
            data-dd-action-name="close-btn"
            onClick={() => {
              setMissionUUIDFocus(null)
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Typography>
      {mission?.state == "IN_PROGRESS" && mission?.eta_remaining_seconds <= ETA_ARRIVING_TIME ? (
        <ArrivingIcon data-testid="arriving_soon_icon" />
      ) : null}
      {mission?.state == "WAIT_ACCEPT" && departureRequests.hasAction ? (
        <Tooltip title={`${mission?.load_id} has outstanding departure actions`}>
          <Icon
            sx={{
              width: "23px",
              backgroundColor: "#fa9a37",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <WarningIcon sx={{ color: "#ffefd6", width: "23px", height: "18px" }}></WarningIcon>{" "}
          </Icon>
        </Tooltip>
      ) : null}
    </div>
  )
}

export default MissionDetailsHeader
