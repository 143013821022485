import { merge } from "lodash"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

const vehicleFilters: Record<string, FilterGroup> = {
  "Vehicle Health": {
    label: "Vehicle Health",
    filters: {
      Operational: {
        label: "Operational",
        value: "OPERATIONAL",
        checked: false,
      },
      Warn: {
        label: "Warn",
        value: "WARN",
        checked: false,
      },
      Error: {
        label: "Error",
        value: "ERROR",
        checked: false,
      },
    },
  },
  "Connectivity Status": {
    label: "Connectivity Status",
    filters: {
      Active: {
        label: "Active",
        value: true,
        checked: false,
      },
      Inactive: {
        label: "Inactive",
        value: false,
        checked: false,
      },
    },
  },
}

const missionFilters: Record<string, FilterGroup> = {
  "My Missions": {
    label: "My Missions",
    filters: {
      "My Missions": {
        label: "My Missions",
        value: true,
        checked: false,
      },
    },
  },
  Health: {
    label: "Health",
    filters: {
      Warning: {
        label: "Warning",
        value: "WARN",
        checked: false,
      },
      Critical: {
        label: "Critical",
        value: "ERROR",
        checked: false,
      },
    },
  },
  Incomplete: {
    label: "Incomplete",
    filters: {
      Blocked: {
        label: "Blocked",
        value: "DEPARTURE_BLOCKED",
        checked: false,
      },
      Canceled: {
        label: "Canceled",
        value: "CANCELED",
        checked: false,
      },
    },
  },
  "Mission Status": {
    label: "Mission Status",
    filters: {
      Scheduled: {
        label: "Scheduled",
        value: "SCHEDULED",
        checked: false,
      },
      "Pre-departure": {
        label: "Pre-departure",
        value: "PREDEPARTURE",
        checked: false,
        children: {
          Preparation: {
            label: "Preparation",
            value: "PREPARING",
            checked: false,
          },
          Review: {
            label: "Review",
            value: "REVIEWING",
            checked: false,
          },
          "Departure Clearance": {
            label: "Departure Clearance",
            value: "CLEARED",
            checked: false,
          },
          "ADS Acceptance": {
            label: "ADS Acceptance",
            value: "WAIT_ACCEPT",
            checked: false,
          },
          Departing: {
            label: "Departing",
            value: "PENDING",
            checked: false,
          },
        },
      },
      "In Transit": {
        label: "In Transit",
        value: "IN_PROGRESS",
        checked: false,
      },
      Arrived: {
        label: "Arrived",
        value: "ARRIVED",
        checked: false,
      },
      Completed: {
        label: "Completed",
        value: "COMPLETED",
        checked: false,
      },
    },
  },
}

interface SortField {
  field: "vehicle-name" | "active-inactive" | "vehicle-id" | "" | "load-id"
  order: "asc" | "desc"
}

interface Filter {
  label: string
  value: string | boolean
  checked: boolean
  children?: Record<string, Filter>
}

interface FilterGroup {
  label: string
  filters: Record<string, Filter>
}

interface SearchStore {
  mission: {
    date: Date | null
    sort: SortField
    filter: Record<string, FilterGroup>
    search: string
    status: string
    hub: string
  }
  vehicle: {
    sort: SortField
    filter: Record<string, FilterGroup>
    search: string
  }
  hub: {
    // TODO - update hub to look like mission and vehicle params, should be done after api is stabilized
    hub: string
    date: Date | null
    sort: string
    filter: string
  }
  updateMissionParams: (params: { date?: Date; sort?: SortField; filter?: string; search?: string }) => void
  updateVehicleParams: (params: { sort?: SortField; filter?: Record<string, FilterGroup>; search?: string }) => void
  updateHubParams: (params: { hub?: string; date?: Date; sort?: string; filter?: string }) => void
}

const useSearchStore = create<SearchStore>()(
  devtools(
    (set) => ({
      mission: {
        date: new Date(),
        sort: {
          field: "load-id",
          order: "asc",
        },
        filter: missionFilters,
        search: "",
        status: "",
        hub: "",
      },
      vehicle: {
        sort: {
          field: "",
          order: "asc",
        },
        filter: vehicleFilters,
        search: "",
      },
      hub: {
        hub: "",
        date: new Date(),
        sort: "",
        filter: "",
      },
      updateMissionParams: (params) => {
        set((state) => ({
          mission: {
            ...merge(state.mission, params),
          },
        }))
      },
      updateVehicleParams: (params) => {
        set((state) => ({
          vehicle: {
            ...merge(state.vehicle, params),
          },
        }))
      },
      updateHubParams: (params) => {
        set((state) => ({
          hub: {
            ...state.hub,
            ...params,
          },
        }))
      },
    }),
    { name: "searchStore" },
  ),
)

export default useSearchStore
export { vehicleFilters }
export type { Filter, FilterGroup, SortField }
