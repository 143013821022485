import { Box, Typography } from "@mui/material"

const MissionInfoBox = ({ title = "", detail = "", subtext = "" }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        color: "#333",
        flex: "1 1 0",
        minHeight: "7em",
        display: "grid",
        placeItems: "center",
        borderRadius: ".5rem",
        p: ".5rem",
      }}
    >
      <Typography variant="body1" component="h1" data-testid={`${title}-info-box`}>
        {title}
      </Typography>
      <Typography variant="h5" component="p" data-testid={`${title}-detail-box`}>
        {detail}
      </Typography>
      <Typography variant="subtitle2" data-testid={`${title}-subtitle-box`}>
        {subtext}
      </Typography>
    </Box>
  )
}

export default MissionInfoBox
