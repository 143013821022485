import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useGetMission } from "../../hooks/missionHooks"
import "./scss/MissionTimeline.scss"

//TODO Capitalize the state vars
const MissionTimeline = ({ hasAction }) => {
  const [states, setStates] = useState({
    preparing: "Not Started",
    reviewing: "Not Started",
    clearance: "Not Started",
    accepted: "Not Started",
    inProgress: "Not Started",
    error: "Error",
  })

  const colorMap = {
    Complete: "rgba(117, 187, 67, 1)",
    "Not Started": "rgba(215, 215, 215, 1)",
    "In Progress": "#42a5f5",
    Error: "#fa3751",
  }

  const mission = useGetMission()

  useEffect(() => {
    if (mission) {
      setTimelineState(mission)
    }
  }, [JSON.stringify(mission)])

  const setTimelineState = (mission) => {
    let newState = { ...states }
    switch (mission.state) {
      case "SCHEDULED":
        newState.preparing = "Not Started"
        newState.reviewing = "Not Started"
        newState.clearance = "Not Started"
        newState.accepted = "Not Started"
        newState.inProgress = "Not Started"
        break
      case "PREPARING":
        newState.preparing = "In Progress"
        newState.reviewing = "Not Started"
        newState.clearance = "Not Started"
        newState.accepted = "Not Started"
        newState.inProgress = "Not Started"
        break
      case "REVIEWING":
        newState.preparing = "Complete"
        newState.reviewing = "In Progress"
        newState.clearance = "Not Started"
        newState.accepted = "Not Started"
        newState.inProgress = "Not Started"
        break
      case "CLEARED":
        newState.preparing = "Complete"
        newState.reviewing = "Complete"
        newState.clearance = "In Progress"
        newState.accepted = "Not Started"
        newState.inProgress = "Not Started"
        break
      case "WAIT_ACCEPT":
        newState.preparing = "Complete"
        newState.reviewing = "Complete"
        newState.clearance = "Complete"
        newState.accepted = "In Progress"
        newState.inProgress = "Not Started"
        break
      case "ACCEPTED":
      case "PENDING":
        newState.preparing = "Complete"
        newState.reviewing = "Complete"
        newState.clearance = "Complete"
        newState.accepted = "Complete"
        newState.inProgress = "In Progress"
        break
      case "IN_PROGRESS":
      case "COMPLETE":
        newState.preparing = "Complete"
        newState.reviewing = "Complete"
        newState.clearance = "Complete"
        newState.accepted = "Complete"
        newState.inProgress = "Complete"
        break
      default:
        newState.preparing = "Not Started"
        newState.reviewing = "Not Started"
        newState.clearance = "Not Started"
        newState.accepted = "Not Started"
        newState.inProgress = "Not Started"
        break
    }
    setStates(newState)
  }

  const buildTimeline = (mission_states) => {
    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: mission_states.preparing == "In Progress" ? "bold" : "light" }}
          >
            Preparation
          </Typography>
          <Box
            border={1}
            borderColor="#A7A7A7"
            data-testid="preparing-box"
            key={"preparing-class-id"}
            sx={{
              backgroundColor: colorMap[mission_states.preparing],
              height: 15,
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
            }}
            className={mission_states.preparing == "In Progress" ? "in-progress-item-timeline" : ""}
            isactive={mission_states.preparing}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: mission_states.reviewing == "In Progress" ? "bold" : "light" }}
          >
            Review
          </Typography>
          <Box
            border={1}
            borderColor="#A7A7A7"
            data-testid="reviewing-box"
            key={"reviewing-class-id"}
            sx={{
              backgroundColor: colorMap[mission_states.reviewing],
              height: 15,
            }}
            className={mission_states.reviewing == "In Progress" ? "in-progress-item-timeline" : ""}
            isactive={mission_states.reviewing}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: mission_states.clearance == "In Progress" ? "bold" : "light" }}
          >
            Clearance
          </Typography>
          <Box
            border={1}
            borderColor="#A7A7A7"
            data-testid="clearance-box"
            sx={{
              backgroundColor: colorMap[mission_states.clearance],
              height: 15,
            }}
            className={mission_states.clearance == "In Progress" ? "in-progress-item-timeline" : ""}
            isactive={mission_states.clearance}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: mission_states.accepted == "In Progress" ? "bold" : "light", whiteSpace: "nowrap" }}
          >
            ADS Acceptance
          </Typography>
          <Box
            border={1}
            borderColor="#A7A7A7"
            data-testid="acceptance-box"
            key={"acceptance-class-id"}
            sx={{
              backgroundColor: hasAction ? colorMap[mission_states.error] : colorMap[mission_states.accepted],
              height: 15,
            }}
            className={!hasAction && mission_states.accepted == "In Progress" ? "in-progress-item-timeline" : ""}
            isactive={mission_states.accepted}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: mission_states.inProgress == "In Progress" ? "bold" : "light" }}
          >
            Departure
          </Typography>
          <Box
            border={1}
            borderColor="#A7A7A7"
            data-testid="inProgress-box"
            key={"inProgress-class-id"}
            sx={{
              backgroundColor: colorMap[mission_states.inProgress],
              height: 15,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
            }}
            className={mission_states.inProgress == "In Progress" ? "in-progress-item-timeline" : ""}
            isactive={mission_states.inProgress}
          />
        </Box>
      </>
    )
  }

  return <>{buildTimeline(states)}</>
}

export default MissionTimeline
