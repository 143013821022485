import Log from "@/features/logs/Log"
import { useGetMission } from "@/hooks/missionHooks"
import useGetMissionLogsQuery from "@/hooks/query/mission/useGetMissionLogsQuery"
import { Box } from "@mui/material"
import _ from "lodash"
import { useMemo } from "react"

const MissionLog = () => {
  const selectedMission = useGetMission()
  const { data: missionLogs } = useGetMissionLogsQuery(selectedMission?.uuid)

  const sortedLogs = useMemo(() => {
    return _.chain(missionLogs).orderBy(["creation_ts"], ["desc"]).value()
  })

  return (
    <Box>
      <Log logs={sortedLogs} dataType={"Mission"} />
    </Box>
  )
}

export default MissionLog
