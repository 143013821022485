import getToken from "@/framework/utils/api/getToken"
import { RouteApiClient } from "@torc-robotics/mm-route-client"

const RouteAPI = new RouteApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_ROUTES_API_URL,
  TOKEN: getToken,
}).default

export default RouteAPI
