import ActionsAPI from "@/framework/utils/api/ActionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

export default function useGetActionsForMissionQuery(uuid: string | null) {
  const missionQuery = useQuery({
    queryKey: ["mission-actions", uuid],
    queryFn: async () => await ActionsAPI.getActionsV1ActionsGet({ missionUuid: uuid }),
    enabled: !isEmpty(uuid),
  })

  return missionQuery
}
