import useGetTableauTokenQuery from "@/hooks/query/tableau/useGetTableauTokenQuery"
import { CircularProgress, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import "./ReportingDashboard.scss"

const ReportingDashboard = () => {
  const { data: tableauToken, isLoading, error, refetch } = useGetTableauTokenQuery()
  const [vizError, setVizError] = useState(null)
  const vizRef = useRef(null)

  useEffect(() => {
    const loadTableauViz = async () => {
      if (!tableauToken) return

      try {
        const { TableauViz } = await import(
          "https://online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"
        )

        const viz = new TableauViz()
        viz.toolbar = "hidden"
        viz.token = tableauToken.token
        viz.src = "https://prod-useast-b.online.tableau.com/#/site/torcrobotics/views/Fleet-OpsMetrics/MissionMetrics"

        if (vizRef.current) {
          vizRef.current.innerHTML = "" // Clear any existing content
          vizRef.current.appendChild(viz)
        }
      } catch (error) {
        console.error("Error loading Tableau viz:", error)
        setVizError("Failed to load Tableau visualization")
      }
    }

    loadTableauViz()
  }, [tableauToken])

  if (isLoading) {
    return <CircularProgress />
  }

  if (error) {
    return (
      <div>
        <Typography color="error">Error loading token: {error.message}</Typography>
        <button onClick={refetch}>Retry</button>
      </div>
    )
  }

  return (
    <div id="main-container" className="reporting-dashboard-container">
      <Typography variant="h4">Reporting Dashboard</Typography>
      <div ref={vizRef} data-testid="viz-container">
        {vizError ? <Typography color="error">{vizError}</Typography> : !tableauToken && <CircularProgress />}
      </div>
    </div>
  )
}

export default ReportingDashboard
