import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { type MissionHistory } from "@/target"
import { useQuery } from "@tanstack/react-query"

const useGetMissionHistoryQuery = (missionUUID: string) => {
  const missionsHistoryQuery = useQuery({
    queryKey: ["mission_history", missionUUID],
    queryFn: async () => await MissionsApi.getMissionHistory({ uuid: missionUUID }),
    select: (data: MissionHistory[]) => {
      return data
    },
  })

  return missionsHistoryQuery
}

export default useGetMissionHistoryQuery
