import { getCriticalityColor, getCriticalitySeverityLabel, getTime } from "@/framework/Utils"
import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material"
import _ from "lodash"
import { useMemo } from "react"
import "./Log.scss"

const Log = ({ logs, dataType }) => {
  const logData = useMemo(() => {
    return (
      logs &&
      _.map(logs, (event) => {
        return (
          <Accordion className="log-event" key={event.log_uuid} disableGutters elevation={0}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Grid container columns={7}>
                <Grid xs={3} item>
                  {event.category ? (
                    <Typography>{_.startCase(_.toLower(_.replace(event.category, /_/g, " ")))}</Typography>
                  ) : (
                    <Typography>{_.startCase(_.toLower(_.replace(event.title, /_/g, " ")))}</Typography>
                  )}
                </Grid>
                <Grid xs={2} item>
                  <Typography sx={{ color: getCriticalityColor(event.level) }}>
                    {getCriticalitySeverityLabel(event.level)}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  <Typography className="log-event-timestamp">{getTime(event.creation_ts, "MM/DD HH:mm A")}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className="log-event-detailed">
              <Typography className="log-text">{event.description}</Typography>
              {event.resolution ? (
                <Typography className="log-resolution-text">
                  <span>Potential actions to resolve:</span>
                  {event.resolution}
                </Typography>
              ) : null}
            </AccordionDetails>
          </Accordion>
        )
      })
    )
  }, [logs])

  return (
    <>
      <Typography className="log-event-title">{`Current ${dataType} Events`}</Typography>
      {logData && !_.isEmpty(logs) ? (
        logData
      ) : (
        <Box className="operational">
          <Typography>No logs at this time</Typography>
        </Box>
      )}
    </>
  )
}

export default Log
