import { getUTCStartDateTimeStamp } from "@/framework/Utils"
import MissionsApi from "@/framework/utils/api/MissionsAPI"
import useDebouncedValues from "@/hooks/useDebouncedValues"
import useSearchStore from "@/store/useSearchStore"
import { useInfiniteQuery } from "@tanstack/react-query"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { type MissionSearchResult } from "@torc-robotics/mm-missions-client"
import { useContext } from "react"

const missionStatus: Record<string, string[]> = {
  departures: ["PENDING", "REVIEWING", "WAIT_ACCEPT", "ACCEPTED", "PREPARING", "SCHEDULED"],
  arrivals: ["IN_PROGRESS"],
}

export default function useGetMissionsQuery() {
  const searchParams = useSearchStore((state) => state.mission)
  const { date, sort, search, filter, status, hub } = useDebouncedValues(searchParams, 500)

  const { userData } = useContext(UserContext)

  const searchTerm = search.trim() !== "" ? search : null
  const emailFilter = filter["My Missions"]?.filters["My Missions"]?.checked ? userData.email : null
  const dateFilter = date != null ? getUTCStartDateTimeStamp(date) : null
  const hubFilter = hub === "All Hubs" ? "" : hub

  let missionState: string[] = [
    ...Object.values(filter["Mission Status"]?.filters),
    ...Object.values(filter.Incomplete?.filters),
  ]
    .filter((f) => f.checked || (f.children != null && Object.values(f.children).some((child) => child.checked)))
    .flatMap((f) => {
      const values: string[] = []

      if (typeof f.value === "string" && f.checked) {
        values.push(f.value)
      }

      if (f.children != null) {
        values.push(
          ...Object.values(f.children)
            .filter((child) => child.checked)
            .map((child) => (typeof child.value === "string" ? child.value : "")),
        )
      }

      return values
    })

  const isDeparturesArrivals = status === "departures" || status === "arrivals"

  if (isDeparturesArrivals && missionState.length > 0) {
    missionState = missionState.filter((statusValue) => missionStatus[status]?.includes(statusValue))
  } else {
    if (isDeparturesArrivals) {
      missionState = missionStatus[status]
    }
  }

  const searchTermEmailDate = {
    ...(searchTerm != null
      ? { searchText: searchTerm }
      : {
          ...(emailFilter != null ? { email: emailFilter } : {}),
          ...(dateFilter != null ? { date: dateFilter } : {}),
        }),
  }

  const healthStatuses = Object.values(filter.Health?.filters)
    .filter((f) => f.checked)
    .map((f) => f.value as string)

  const missionsQuery = useInfiniteQuery({
    getNextPageParam: (lastPage: MissionSearchResult, pages) =>
      lastPage.last_key !== undefined ? lastPage.last_key : undefined,
    initialPageParam: 0,
    queryKey: [
      "missions",
      { sort, healthStatuses, email: userData.email, state: missionState, status, hub: hubFilter },
      searchTermEmailDate,
      isDeparturesArrivals,
    ],
    queryFn: async ({ pageParam }) => {
      if (isDeparturesArrivals && missionState.length === 0) {
        return { missions: [] }
      }

      return await MissionsApi.getMissions({
        ...searchTermEmailDate,
        healthStatuses,
        active: true,
        maxIterate: 20000,
        searchType: "MISSIONS",
        pageSize: 30,
        ...(pageParam !== null && { startKey: JSON.stringify(pageParam) }),
        scanIndexForward: sort.order === "asc",
        states:
          missionState.length > 0
            ? missionState
            : [
                "IN_PROGRESS",
                "PENDING",
                "REVIEWING",
                "WAIT_ACCEPT",
                "ACCEPTED",
                "PREPARING",
                "SCHEDULED",
                "CLEARED",
                "ARRIVED",
              ],
        hub: hubFilter,
      })
    },
    refetchOnMount: false,
  })

  return missionsQuery
}
