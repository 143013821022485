import { getFormattedDate } from "@/framework/Utils"
import { useGetMission } from "@/hooks/missionHooks"
import useGetMissionNotesQuery from "@/hooks/query/notes/useGetMissionNotesQuery"
import { Add, Edit } from "@mui/icons-material"
import { Chip, Drawer, IconButton, LinearProgress, Paper, Tooltip, Typography } from "@mui/material"
import { onlineManager, useIsRestoring, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import _ from "lodash"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import ConfirmationDialog from "./ConfirmationDialog"
import CreateNote from "./CreateNote"
import "./MissionNotes.scss"
import usersApi from "@/framework/utils/api/UsersAPI"
import usePostNoteMutation from "@/hooks/query/notes/usePostNoteMutation"
import usePutNoteMutation from "@/hooks/query/notes/usePutNoteMutation"

const MissionViewNotes = ({ missionUUID, drawerView = false }) => {
  const mission = useGetMission()
  const uuid = mission?.uuid ?? missionUUID
  const [offlineIds, setOfflineIds] = useState([])
  const [connectivity, setConnectivity] = useState(onlineManager.isOnline())
  const [confirm, setConfirm] = useState(false)
  const queryClient = useQueryClient()

  const { data: getMeData, isLoading: getMeIsLoading } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => await usersApi.getMeV1MeGet(),
  })

  const { data: missionNotes, isLoading: notesIsLoading } = useGetMissionNotesQuery(uuid)

  const { mutate: postNoteMutate } = usePostNoteMutation()
  const { mutate: putNoteMutate } = usePutNoteMutation()

  const [offlinePostNotes, setOfflinePostNotes] = useState(() => {
    const initialNotes = !onlineManager.isOnline() && !notesIsLoading && missionNotes ? missionNotes : []

    const mutations = queryClient.getMutationCache().getAll()

    const pendingMutations = mutations.filter((mutation) => mutation?.state?.status === "pending")

    const pendingNotes = pendingMutations.map((mutation) => {
      const item = mutation?.state?.variables
      const note = item?.noteRequest

      return {
        body: note?.body,
        created_by: note?.created_by,
        user_creation_ts: note?.user_creation_ts || DateTime.now().toISO(),
        delay: note?.delay === "true",
        delay_value: note?.delay_value,
        mission_note_tags: note?.mission_note_tags,
        mission_note_type: note?.mission_note_type,
        expiration_ts: note?.expiration_ts || 0,
        pending: true,
        uuid: item?.noteUuid,
      }
    })
    return [...initialNotes, ...pendingNotes]
  })

  const [noteOpen, setNoteOpen] = useState(false)
  const [editNote, setEditNote] = useState(null)

  const addNewNote = (data) => {
    if (mission) {
      var postNote = {
        mission_note_type: data.noteType,
        mission_note_tags: data.noteTags,
        delay: data.isDelay.toString(),
        delay_value: data.delayValue,
        body: data.noteValue,
        created_by: _.get(getMeData, "email", ""),
        user_creation_ts: data.timestamp,
        vehicle_id: mission.vehicle_id,
      }

      if (editNote) {
        delete postNote.user_creation_ts
        putNoteMutate({ noteUuid: editNote.uuid, noteRequest: postNote })
        setEditNote(null)
      } else {
        postNoteMutate({ missionUuid: mission?.uuid, noteRequest: postNote })
      }
      setNoteOpen(false)

      if (!onlineManager.isOnline()) {
        toast.info(`You are offline and have unsynced changes`)
      }
    }
  }

  onlineManager.subscribe((onlineState) => {
    if (!onlineManager.isOnline()) {
      setOfflinePostNotes(missionNotes)
    } else {
      setOfflinePostNotes(missionNotes)
      setOfflineIds([])
    }
    setConnectivity(onlineState)
  })

  const updatePendingMutations = (event) => {
    var mutation = event?.mutation
    // only run if offline, mutation is pending, hasn't been accounted

    if (
      !connectivity &&
      mutation &&
      mutation.state.status == "pending" &&
      !offlineIds.some((id) => id === mutation.mutationId)
    ) {
      offlineIds.push(mutation.mutationId)

      var item = mutation.state.variables
      var note = item?.noteRequest
      const newNote = {
        body: note?.body,
        created_by: note?.created_by,
        user_creation_ts: note?.user_creation_ts || DateTime.now().toISO(),
        delay: note?.delay === "true",
        delay_value: note?.delay_value,
        mission_note_tags: note?.mission_note_tags,
        mission_note_type: note?.mission_note_type,
        expiration_ts: note?.expiration_ts || 0,
        pending: true,
      }

      if (item?.noteUuid) {
        let noteToUpdate = offlinePostNotes.find((obj) => obj.uuid === item.noteUuid)
        if (noteToUpdate) {
          noteToUpdate.mission_note_type = newNote.mission_note_type
          noteToUpdate.mission_note_tags = newNote.mission_note_tags
          noteToUpdate.delay = newNote.delay
          noteToUpdate.delay_value = newNote.delay_value
          noteToUpdate.body = newNote.body
          noteToUpdate.created_by = newNote.created_by
          noteToUpdate.pending = newNote.pending
        }
      } else {
        setOfflinePostNotes((prevNote) => [...prevNote, newNote])
      }
    }
  }

  useEffect(() => {
    const unsubscribeFn = queryClient.getMutationCache().subscribe(updatePendingMutations)

    return () => {
      unsubscribeFn()
    }
  }, [queryClient, connectivity])

  const handleClose = () => {
    setConfirm(false)
    setNoteOpen(false)
    setEditNote(null)
  }

  const isRestoring = useIsRestoring()

  const createMissionNotesRows = (notes) =>
    notes.map((note, i) => (
      <div
        key={i}
        data-testid="note-row"
        className="note-grid__body"
        style={{
          backgroundColor: note?.pending ? "#eefaff" : "#ffffff",
        }}
      >
        <Typography data-testid="note-type-row" className="note-grid__body__type">
          {note.mission_note_type}
        </Typography>
        <Typography data-testid="note-creation-date" className="note-grid__body__ts">
          {getFormattedDate(note.user_creation_ts, "D ttt", null)}
        </Typography>
        <Typography data-testid="note-delay" className="note-grid__body__delay">
          {note.delay && note.delay_value ? note.delay_value.toString() : "None"}
        </Typography>
        <Typography data-testid="note-created-by" className="note-grid__body__user">
          <a href={`mailto:${note.created_by}`} onClick={(e) => e.stopPropagation()}>
            {note.created_by}
          </a>
        </Typography>
        <Typography data-testid="edit-note" className="note-grid__body__edit">
          <IconButton
            size="small"
            data-testid="edit-note-button"
            color="primary"
            disabled={!note?.uuid}
            onClick={() => {
              setEditNote(note)
              setNoteOpen(true)
            }}
          >
            <Edit />
          </IconButton>
        </Typography>
        <div className="note-grid__body__tags">
          {note.mission_note_tags.length === 0
            ? null
            : note.mission_note_tags.map((tag) => <Chip key={tag.uuid} label={tag.tag_name} size="small" />)}
        </div>
        <Typography data-testid="note-body" variant="body1" className="note-grid__body__note">
          {note.body}
        </Typography>
      </div>
    ))

  return (
    <>
      {noteOpen && (
        <Drawer anchor="right" open={noteOpen} onClose={() => setConfirm(true)} data-testid="missionNotes">
          <ConfirmationDialog open={confirm} onClose={() => setConfirm(false)} onAgree={handleClose} />
          <CreateNote
            data-testid="note-new1"
            onClose={() => setConfirm(true)}
            addNewNote={addNewNote}
            timestamp={DateTime.now().toISO()}
            priorNote={editNote}
          />
        </Drawer>
      )}
      <Paper aria-label="notes table" className="note-grid">
        <div className="note-grid__header">
          {!drawerView ? (
            <Tooltip className="note-grid__header__add" title="New Note">
              <IconButton
                data-testid="Add-Icon"
                color="info"
                aria-label="add-note"
                size="small"
                id="add-note-button"
                onClick={() => setNoteOpen(true)}
              >
                <Add />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          <Typography className="note-grid__header__type">Note Type</Typography>
          <Typography className="note-grid__header__ts">Timestamp</Typography>
          <Typography className="note-grid__header__delay">Delay (mins)</Typography>
          <Typography className="note-grid__header__user">User</Typography>
          <Typography className="note-grid__header__edit">Edit</Typography>
        </div>

        {!connectivity || (offlinePostNotes.length && isRestoring) ? (
          // offline or just coming back online and restoring with non-empty notes
          createMissionNotesRows(
            offlinePostNotes?.sort(
              (a, b) =>
                DateTime.fromISO(b.user_creation_ts).toMillis() - DateTime.fromISO(a.user_creation_ts).toMillis(),
            ),
          )
        ) : notesIsLoading ? (
          <LinearProgress sx={{ width: "90vw", minWidth: "640px" }} />
        ) : missionNotes ? (
          createMissionNotesRows(
            missionNotes?.sort(
              (a, b) =>
                DateTime.fromISO(b.user_creation_ts).toMillis() - DateTime.fromISO(a.user_creation_ts).toMillis(),
            ),
          )
        ) : null}
      </Paper>
    </>
  )
}

export default MissionViewNotes
