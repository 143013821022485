import { useAuthenticator } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"
import { useEffect, useState } from "react"

/**
 * Fetches all User claims from auth provider
 */
export const useUserClaims = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
  const [userClaims, setUserClaims] = useState({})

  useEffect(() => {
    if (authStatus === "authenticated") {
      setUserClaims({
        email: user.signInUserSession.idToken.payload.email,
      })
    } else {
      setUserClaims({})
    }
  }, [authStatus])

  return userClaims
}

/**
 * Fetches geo credentials from AWS
 */
export const useGeoCreds = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
  const [geoCreds, setGeoCreds] = useState()
  useEffect(() => {
    if (authStatus === "authenticated") {
      console.debug("fetching geo credentials -->")
      Auth.currentUserCredentials().then((result) => {
        setGeoCreds(result)
      })
    }
    return () => {
      setGeoCreds() // Clean on unmount to prevent memory leaks
    }
  }, [authStatus, user])

  return geoCreds
}
