import useGetRouteAdvisorViasQuery from "@/hooks/query/routes/useGetRouteAdvisorViasQuery"
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Button, MenuItem, Select, Typography } from "@mui/material"
import _ from "lodash"
import React, { useState } from "react"
import { StyledButton, StyledInput, StyledInputRoot } from "../css/LapComponentStyles"
import "../css/RouteSelection.scss"

const ArrivalSelections = ({
  map_version,
  current_location,
  updateArrivalDestination,
  updateArrivalWaypoint,
  action,
  setTriggerQuery,
}) => {
  const [addStop, setAddStop] = useState(true)
  const [laps, setLaps] = useState(Number(1))
  const isCompleted = action?.state === "COMPLETED"

  const [arrivalLocation, setArrivalLocation] = useState({
    location: "",
    Destination: {},
  })
  const [arrivalVias, setArrivalVias] = useState()

  const { data: routeVias } = useGetRouteAdvisorViasQuery(
    `${current_location?.lat}, ${current_location?.lon}`,
    map_version,
  )

  const options = {
    "VTTI Surface Streets": {
      Destination: { lat: 37.189456, lon: -80.395041 },
    },
    "VTTI Highway": {
      Destination: { lat: 37.187241, lon: -80.396111 },
    },
    "Torc Calibration Pad": {
      Destination: { lat: 37.19009, lon: -80.391501 },
    },
    Uvalde: {
      Destination: { lat: 29.106394, lon: -99.754668 },
    },
    Buda: {
      Destination: { lat: 30.06686912562298, lon: -97.83602260207392 },
    },
    DFW: {
      Destination: { lat: 33.0037032905087, lon: -97.31751069115307 },
    },
    Laredo: {
      Destination: { lat: 27.61924745, lon: -99.49818275000001 },
    },
  }

  const handleDestinationChange = async (event, key, option) => {
    setArrivalLocation({ ...option, location: key })
  }

  const handleViasChange = async (event) => {
    setArrivalVias(event.target.value)
  }

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: "increment",
            "data-testid": "lap-count_increment",
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    )
  })

  return (
    <div className="arrival-selections">
      {addStop && !isCompleted ? (
        <Button
          data-testid="add-stop-btn"
          className="arrival-selections__add-stop-btn"
          onClick={() => setAddStop(false)}
        >
          <AddIcon /> Add Stop
        </Button>
      ) : isCompleted ? (
        <>
          <div className="arrival-selections__waypoint_title">
            <Typography className="mission-route-details__subheading">WAYPOINT LOCATION</Typography>
          </div>
          <div className="arrival-selections__waypoint_route">
            <Typography
              className="mission-route-details__destination"
              data-testid="arrival-selections_waypoint_completed"
            >
              {action?.formData["via_name"]}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className="arrival-selections__waypoint_title">
            <Typography className="mission-route-details__subheading">WAYPOINT LOCATION</Typography>
          </div>
          <div className="arrival-selections__waypoint_route">
            <>
              <Select
                id="arrival-selections__waypoint_route"
                data-testid="arrival-selections__waypoint_route"
                inputProps={{ "aria-label": "Without label" }}
                className="mission-route-details__arrival-options"
                readOnly={isCompleted}
                defaultValue=""
                onChange={(event) => handleViasChange(event)}
              >
                {_.get(routeVias, "via-options", []).map((route) => (
                  <MenuItem sx={{ fontSize: "14px" }} key={route.name} value={route}>
                    {route.name}
                  </MenuItem>
                ))}
              </Select>
            </>
            <>
              <Button
                data-testid="remove-stop-btn"
                className="remove-stop-btn"
                onClick={() => {
                  setAddStop(true)
                  setArrivalVias()
                  updateArrivalWaypoint([])
                }}
              >
                <RemoveIcon /> Remove Stop
              </Button>
            </>
          </div>
        </>
      )}

      <>
        {isCompleted ? (
          <>
            <div className="departure-selections__arrival_title">
              <Typography className="mission-route-details__subheading">ARRIVAL LOCATION</Typography>
            </div>
            <div className="departure-selections____arrival_route">
              <Typography
                className="mission-route-details__destination"
                data-testid="arrival-selections__arrival_route_completed"
              >
                {action?.formData["arrival_location"]}
              </Typography>
            </div>
          </>
        ) : (
          <>
            <div className="arrival-selections__arrival_title">
              <Typography className="mission-route-details__subheading">ARRIVAL LOCATION</Typography>
            </div>
            <div className="arrival-selections__arrival_route">
              <Select
                id="arrival-selections__arrival_route"
                data-testid="arrival-selection"
                className="mission-route-details__arrival-options"
                inputProps={{ "aria-label": "Without label" }}
                value={arrivalLocation.location}
                onChange={(event) => handleDestinationChange(event, event.target.value, options[event.target.value])}
              >
                {Object.keys(options)
                  .sort()
                  .map((locationName, index) => (
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      key={index}
                      value={locationName}
                      disabled={options[locationName]?.disabled}
                    >
                      {locationName}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </>
        )}

        {isCompleted ? (
          <>
            <div className="arrival-selections__laps_title">
              <Typography className="mission-route-details__subheading">NO. OF LAPS</Typography>
            </div>
            <div className="arrival-selections__laps_count">
              <Typography
                className="mission-route-details__destination"
                data-testid="arrival-selections__number_laps_completed"
              >
                {action?.formData["lap_count"]}
              </Typography>
            </div>
          </>
        ) : _.get(arrivalVias, "is-loop") ? (
          <>
            <div className="arrival-selections__laps_title">
              <Typography className="mission-route-details__subheading">NO. OF LAPS</Typography>
            </div>
            <div className="arrival-selections__laps_count">
              <NumberInput
                id="arrivals__number_input"
                data-testid="arrivals__number_input"
                value={laps}
                disabled={isCompleted}
                onChange={(event, newValue) => setLaps(newValue)}
                min={1}
                max={99}
              />
            </div>
          </>
        ) : null}
        {!isCompleted ? (
          <Button
            data-testid="load-routes"
            className="arrival-selections__load-routes-btn"
            type="submit"
            variant="contained"
            disabled={!Object.hasOwn(arrivalLocation.Destination || {}, "lat")}
            onClick={() => {
              updateArrivalDestination(arrivalLocation)
              updateArrivalWaypoint(arrivalVias ? [{ latlons: arrivalVias?.latlons, value: laps }] : [])
              setTriggerQuery(true)
            }}
          >
            Load Routes
          </Button>
        ) : null}
      </>
    </div>
  )
}

export default ArrivalSelections
