import RouteAPI from "@/framework/utils/api/RouteAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"
import { useMemo } from "react"

const useGetVehicleRouteQuery = (vehicleId: string) => {
  const routeData = useQuery({
    queryKey: ["route", vehicleId],
    queryFn: async () => await RouteAPI.getRoute({ vehicleId }),
    enabled: !isEmpty(vehicleId),
    retry: 2,
  })

  const geojson = useMemo(() => {
    // To support multi lines look here: https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
    const geoData = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    }
    if (routeData?.data !== undefined && Array.isArray(routeData.data)) {
      routeData.data.forEach((routeCoord) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        geoData.geometry.coordinates.push([routeCoord.longitude, routeCoord.latitude])
      })
    }
    return geoData
  }, [routeData?.data])

  return geojson
}

export default useGetVehicleRouteQuery
