import ClearIcon from "@mui/icons-material/Clear"
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React, { useState } from "react"
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa"
import useSearchStore from "../../../store/useSearchStore"
import SearchFilters from "../SearchFilters"
import SearchInput from "../SearchInput"
import "./MissionFilters.scss"
import "../Search.scss"

const SORT_OPTIONS = {
  "Departure Time": "tms_actual_departure_time",
  "Time Remaining": "eta_remaining_seconds",
  "ETA (Arrival Time)": "tms_estimated_arrival_time",
  "Delay Times": "eta_remaining_seconds",
  "Truck A-Z": "vehicle_id",
  "Mission ID A-Z": "load_id",
}

const LOCATIONS = [
  "Dallas, TX",
  "Austin, TX",
  "Loredo, TX",
  "Blacksburg, VA",
  "Uvalde, TX",
  "Albuquerque, NM",
  "All Hubs",
]

const LocationSelect = ({ location, handleHubChange }) => {
  return (
    <FormControl className="custom-container">
      <Select
        className="location-select"
        labelId="demo-simple-select-label"
        data-testid="location-select"
        value={location}
        label="Location"
        onChange={handleHubChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "#0083d4",
              color: "#ffffff",
            },
          },
        }}
      >
        {LOCATIONS.map((item) => (
          <MenuItem key={item} value={item} data-dd-action-name={`filter-${item}`}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const SearchSection = ({ search, updateSearch, sort, filter, date }) => {
  return (
    <div className="search" data-testid="mission-search" data-dd-action-name="mission-search">
      <SearchInput
        search={search}
        updateSearch={(search) => updateSearch({ search })}
        placeholder="Search Mission IDs"
      />

      <div className="searchbar__search-filters">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="DATE"
            shrink={true}
            inputFormat="MM/dd/yyyy"
            className="custom-component"
            value={date}
            onChange={(newDate) => updateSearch({ date: newDate })}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                variant="standard"
                sx={{ width: "136px" }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {date && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="clear date"
                            onClick={() => updateSearch({ date: null })}
                            style={{ marginRight: "-15px" }}
                          >
                            <ClearIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {props.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl size="small" className="custom-component" variant="standard">
          <InputLabel id="sort-label" shrink={true}>
            SORT
          </InputLabel>
          <Select
            data-testid="sort-select"
            data-dd-action-name="sort"
            value={sort.field}
            labelId="sort-label"
            id="sort-select"
            label="Sort"
            onChange={(e) => updateSearch({ sort: { field: e.target.value } })}
          >
            <MenuItem data-testid="sort-clear" data-dd-action-name="sort-clear" value="">
              Clear
            </MenuItem>
            {Object.keys(SORT_OPTIONS).map((key) => (
              <MenuItem key={key} value={SORT_OPTIONS[key].toLowerCase()} data-dd-action-name={`sort-${key}`}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          size="small"
          className="searchbar__sort-icon"
          data-testid="sort-order"
          data-dd-action-name="sort-order"
          onClick={() => updateSearch({ sort: { order: sort.order === "asc" ? "desc" : "asc" } })}
        >
          {sort.order === "asc" ? <FaSortAmountUp /> : <FaSortAmountDownAlt />}
        </IconButton>

        <SearchFilters
          filterOptions={filter}
          updateFilter={updateSearch}
          additionalFilters={{
            ...(date && { date: Intl.DateTimeFormat("en-US").format(date) }),
          }}
        ></SearchFilters>
      </div>
    </div>
  )
}

const MissionFilters = () => {
  const updateMissionParams = useSearchStore((state) => state.updateMissionParams)

  const { search, filter, sort, date } = useSearchStore((state) => state.mission)

  const [location, setLocation] = useState("All Hubs")
  const [status, setStatus] = useState("Departures & Arrivals")

  const handleStatusChange = (event, status) => {
    setStatus(status == null ? "all" : status)
    updateMissionParams({ status: status })
  }

  const handleHubChange = (e) => {
    setLocation(e.target.value)
    updateMissionParams({ hub: e.target.value.toUpperCase() })
  }

  return (
    <>
      <div className="custom-container">
        <LocationSelect location={location} handleHubChange={handleHubChange} />

        <ToggleButtonGroup value={status} exclusive onChange={handleStatusChange} className="custom-toggle-group">
          <ToggleButton value="all" className="custom-toggle-button">
            All
          </ToggleButton>
          <ToggleButton value="departures" className="custom-toggle-button">
            Departures
          </ToggleButton>
          <ToggleButton value="arrivals" className="custom-toggle-button">
            Arrivals
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <SearchSection search={search} updateSearch={updateMissionParams} sort={sort} filter={filter} date={date} />

      <Box className="departures-bar">{status == "all" ? "Departures & Arrivals" : status}</Box>
    </>
  )
}

export default React.memo(MissionFilters)
