import { NotesApiV2 } from "@/framework/utils/api/NotesAPI"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"

const postNoteMutationFn = async ({ missionUuid, noteRequest }) => {
  return await NotesApiV2.postNoteV2NotesMissionsMissionUuidPost({
    missionUuid: missionUuid,
    requestBody: noteRequest,
  }).catch((error) => {
    throw error
  })
}

export { postNoteMutationFn }

const postNoteOnSuccess = ({ missionUuid, queryClient }) => {
  toast.success("Successfully created new mission note.")
  return queryClient.invalidateQueries({ queryKey: ["mission", missionUuid, "notes"] })
}

export { postNoteOnSuccess }

const postNoteOnError = () => {
  toast.error(
    "An error occurred when attempting to create a new note. Please try again, or contact support if the error persists.",
  )
}

export { postNoteOnError }

const usePostNoteMutation = () => {
  const queryClient = useQueryClient()

  const postNoteMutation = useMutation({
    mutationKey: ["mission", "notes-post"],
    mutationFn: ({ missionUuid, noteRequest }) =>
      postNoteMutationFn({ missionUuid: missionUuid, noteRequest: noteRequest }),
    onSuccess: (data) => postNoteOnSuccess({ missionUuid: data.mission_uuid, queryClient: queryClient }),
    onError: () => postNoteOnError(),
  })

  return postNoteMutation
}

export default usePostNoteMutation
