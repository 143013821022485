/* istanbul ignore file */

import config from "@/config"
import useCenteredVehicle from "@/hooks/map/useCenteredVehicle"
import useGetVehicleRouteQuery from "@/hooks/query/routes/useGetVehicleRoutesQuery"
import { useGetVehicleId } from "@/hooks/vehiclesV2Hooks"
import { Signer } from "@aws-amplify/core"
import { datadogRum } from "@datadog/browser-rum"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import LayersIcon from "@mui/icons-material/Layers"
import { IconButton } from "@mui/material"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import maplibregl from "maplibre-gl"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import Map, { FullscreenControl, Layer, NavigationControl, Source, useControl } from "react-map-gl"
import LoadScreen from "../../components/LoadScreen"
import useVehiclesLayer from "../../hooks/map/layers/useVehiclesLayer"
import { useGeoCreds } from "../../hooks/UserHooks"
import useMapStore from "../../store/useMapStore"
import HoverWrapper from "./hover/HoverWrapper"
import MapLayers from "./MapLayers"
import { dataLayer, uvaldeFillStyle, uvaldeLineStyle } from "./mapStyle"
import "./scss/TrafficMap.scss"
import uvaldeGeoJson from "./uvalde.geo.json"

const initViewstate = {
  longitude: -98.5795,
  latitude: 39.8283,
  zoom: 4,
}

const TrafficMapVector = () => {
  const { userData } = useContext(UserContext)
  const showCoords = userData.preferences.mapLayerSelection.showCoords

  const geoCreds = useGeoCreds()

  const mapRef = useMapStore((state) => state.mapRef)
  const hoverInfo = useMapStore((state) => state.hoverInfo)
  const breakCenter = useMapStore((state) => () => state.setIsCentered(false))

  const vehiclesLayer = useVehiclesLayer()
  const vehicleId = useGetVehicleId()
  const routeData = useGetVehicleRouteQuery(vehicleId) ?? []

  useMapResizeListener()
  useCenteredVehicle()

  // TODO - this shouldn't be a useState here, but it's a quick fix for now
  // need to create store for JUST map state (details, layers, styles, etc..)
  const [isMapLayersOpen, setIsMapLayersOpen] = useState(false)
  const [screenCoords, setScreenCoords] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)

  // Variable for counting user interactions
  let userInteractionCount = 0

  // Sign AWS Request -- this is a copy until HERE Map tiles are put in place to ensure that the base map has some method of
  // base tiles
  const transformRequest = (url, resourceType) => {
    if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
      // resolve to an AWS URL for getting a style descriptor
      url = `${config.AWS_MAP_URL}`
    }
    // // only sign AWS requests (with the signature as part of the query string)
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds.accessKeyId,
          secret_key: geoCreds.secretAccessKey,
          session_token: geoCreds.sessionToken,
        }),
      }
    }

    // don't sign non-AWS requests
    return { url: url || "" }
  }

  const onViewChange = useCallback(({ viewState }) => {
    userInteractionCount++
    datadogRum.addAction("traffic-map", {
      value: userInteractionCount,
      items: viewState,
    })
  }, [])

  const coordinateLayer = useMemo(() => {
    if (screenCoords.length > 0 && showCoords) {
      return (
        <div className="on-screen-coords dark-map-esri">
          Lat: {screenCoords[1].toFixed(4)}, Lon: {screenCoords[0].toFixed(4)}
        </div>
      )
    }
  }, [screenCoords, showCoords])

  return geoCreds ? (
    <>
      <Map
        ref={mapRef}
        id="map"
        style={{
          transition: "opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
          opacity: mapLoaded ? 1 : 0,
          filter: mapLoaded ? "blur(0px)" : "blur(100px)",
          pointerEvents: mapLoaded ? "auto" : "none",
        }}
        dragRotate={false}
        touchRotate={false}
        initialViewState={initViewstate}
        //TODO, implement me
        mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
        mapLib={maplibregl}
        transformRequest={transformRequest}
        onMoveEnd={onViewChange}
        onDrag={breakCenter}
        onLoad={() => {
          setMapLoaded(true)
        }}
      >
        <>
          <div className="map-options">
            <IconButton
              className="traffic-map-layers-btn"
              disableRipple
              color={isMapLayersOpen ? "primary" : "inherit"}
              onClick={() => setIsMapLayersOpen(!isMapLayersOpen)}
              data-testid="map-layers-button"
              data-dd-action-name="map-layers-button"
            >
              <LayersIcon fontSize="small" />
            </IconButton>
            <FullscreenControl className="map-nav-btn" data-testid="map-fullscreen-button" />
            <NavigationControl className="map-nav-btn" showCompass={false} data-testid="map-controls-button" />
          </div>
          <div className="map-layers">{isMapLayersOpen ? <MapLayers /> : null}</div>
          <Source id="geojsonRoute" type="geojson" data={routeData}>
            <Layer {...dataLayer} />
          </Source>
          <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
            <Layer {...uvaldeFillStyle} />
            <Layer {...uvaldeLineStyle} />
          </Source>
        </>

        <DeckGLOverlay
          layers={[vehiclesLayer]}
          onHover={(info) => {
            if (info.coordinate) {
              setScreenCoords(info.coordinate)
            }
          }}
        />
        {coordinateLayer}
        {hoverInfo?.object && <HoverWrapper hoverInfo={hoverInfo} />}
      </Map>
    </>
  ) : (
    <LoadScreen />
  )
}

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

/**
 * useMapResizeListener - listens to window resize events and updates the map container size
 */
const useMapResizeListener = () => {
  const mapRef = useMapStore((state) => state.mapRef)
  const setContainerSize = useMapStore((state) => state.setContainerSize)

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize()
        const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect()
        setContainerSize({ width, height })
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    screen.orientation.addEventListener("change", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      screen.orientation.removeEventListener("change", handleResize)
    }
  }, [mapRef.current])
}

export default TrafficMapVector

export { DeckGLOverlay, initViewstate }
