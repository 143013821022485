import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionRouteQuery = (uuid) => {
  const missionRouteQuery = useQuery({
    queryKey: ["selected-route", uuid],
    queryFn: async () => await MissionsApi.getMissionRoutes({ missionUuid: uuid, selected: true }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !isEmpty(uuid),
  })

  return missionRouteQuery
}

export default useGetMissionRouteQuery
