import { MissionsApiV2 } from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetMissionLogsQuery = (missionUUID: string) => {
  const missionsLogsQuery = useQuery({
    queryKey: ["logs", missionUUID],
    queryFn: () => MissionsApiV2.getMissionLogs({ missionUuid: missionUUID }),
    enabled: !isEmpty(missionUUID),
  })

  return missionsLogsQuery
}

export default useGetMissionLogsQuery
