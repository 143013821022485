import { getTime } from "@/framework/Utils"
import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link, Typography } from "@mui/material"
import _ from "lodash"
import "./History.scss"

const History = ({ history }) => {
  return (
    <>
      <Typography className="history-event-title">{`Latest History`}</Typography>
      {history && !_.isEmpty(history) ? (
        _.map(history, (record) => {
          return (
            <Accordion className="history-event" key={record?.uuid} disableGutters elevation={0}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Grid container columns={12}>
                  <Grid xs={3} item>
                    <Typography>{record?.service}</Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography>{record?.title}</Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography>Mission State: {record?.mission_state}</Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography className="history-event-timestamp">
                      {getTime(record?.created, "MM/DD HH:mm A")}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className="history-event-detailed">
                <Typography className="history-text">{record?.description}</Typography>
                <Link target="_blank" rel="noopener" href={record?.dd_link} className="history-log-link">
                  Datadog Logs
                </Link>
              </AccordionDetails>
            </Accordion>
          )
        })
      ) : (
        <Box className="operational">
          <Typography>No history at this time</Typography>
        </Box>
      )}
    </>
  )
}

export default History
