import MissionsApi from "@/framework/utils/api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash"

const useGetArrivalRoutesQuery = (uuid: string) => {
  const arrivalRoutesQuery = useQuery({
    queryKey: ["arrival-routes", uuid],
    queryFn: async () => await MissionsApi.getArrivalRoutes({ uuid }),
    enabled: !isEmpty(uuid),
  })

  return arrivalRoutesQuery
}

export default useGetArrivalRoutesQuery
