import useVehicleHover from "@/hooks/map/layers/useVehicleHover"
import { Box, Card, Divider, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import VehiclesHoverTooltip from "../map/hover/VehiclesHoverTooltip"
import MissionDepartureDetails from "./MissionDepartureDetails"
import MissionDetailsHeader from "./MissionDetailsHeader"
import headerStyles from "./scss/MissionDetailsHeader.module.scss"

const MissionListCard = ({ mission, conflicts, from = "missions" }) => {
  const handleHover = useVehicleHover()

  if (!mission) {
    return (
      <Card data-testid="no-data-card">
        <Box>
          <Typography className="mission-list-title">No Mission Data</Typography>
        </Box>
      </Card>
    )
  }

  return (
    <Link
      data-testid={`mission-card-link-${mission.load_id}`}
      onMouseEnter={() => handleHover(<VehiclesHoverTooltip vehicleId={mission.vehicle_id} />, mission.vehicle_id)}
      onMouseLeave={() => handleHover(<VehiclesHoverTooltip vehicleId={""} />)}
      onClick={() => handleHover(<VehiclesHoverTooltip vehicleId={""} />)}
      to={`/missions/${encodeURIComponent(mission.load_id)}`}
      className="mission-list-card"
      state={{ from }}
    >
      <MissionDetailsHeader conflicts={conflicts} mission={mission} hideBackNavigation />
      <Divider />
      <div className={headerStyles["mission-details-header__row"]}>
        <MissionDepartureDetails mission={mission} />
      </div>
    </Link>
  )
}

export default MissionListCard
