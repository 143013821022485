import ClearIcon from "@mui/icons-material/Clear"
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { useContext } from "react"
import useSearchStore from "../../store/useSearchStore"
import "./Search.scss"
import SearchFilters from "./SearchFilters"

const HubSearch = () => {
  const { hub, date, filter, sort } = useSearchStore((state) => state.hub)
  const updateHubParams = useSearchStore((state) => state.updateHubParams)
  const { userData } = useContext(UserContext)

  const hubs = ["Albuquerque, NM", "Blacksburg, VA", "Austin, TX", "Portland, OR"]

  return (
    <div className="search" data-testid="hub-search">
      <FormControl size="small" sx={{ minWidth: "96px" }} variant="standard">
        <InputLabel id="hub-label" shrink={true}>
          Hub
        </InputLabel>
        <Select
          data-testid="hub"
          data-dd-action-name="hub"
          labelId="hub-label"
          id="hub-select"
          value={hub}
          label="Hub"
          onChange={(e) => updateHubParams({ hub: e.target.value })}
        >
          {hubs.map((hub) => (
            <MenuItem key={hub} value={hub}>
              {hub}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <SearchFilters
        additionalFilters={{
          ...(date && { date: Intl.DateTimeFormat("en-US").format(date) }),
          ...(filter && { filter: "My Missions" }),
          ...(sort && { sort }),
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="MM/dd/yyyy"
            value={date}
            onChange={(newDate) => updateHubParams({ date: newDate })}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                variant="standard"
                sx={{ maxWidth: "180px" }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {date && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="clear date"
                            onClick={() => updateHubParams({ date: null })}
                            // hacky, couldnt figure out how to get rid of the default margin
                            style={{ marginRight: "-12px" }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {props.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl size="small" sx={{ minWidth: "96px" }} variant="standard">
          <InputLabel id="sort-label" shrink={true}>
            Sort
          </InputLabel>
          <Select
            data-testid="sort"
            data-dd-action-name="sort"
            labelId="sort-label"
            id="sort-select"
            value={sort}
            label="Sort"
            onChange={(e) => updateHubParams({ sort: e.target.value })}
          >
            <MenuItem data-testid="sort-clear" data-dd-action-name="sort-clear" value={""}>
              Clear
            </MenuItem>
            <MenuItem data-testid="sort-ascending" data-dd-action-name="sort-ascending" value={"asc"}>
              A-Z Ascending
            </MenuItem>
            <MenuItem data-testid="sort-descending" data-dd-action-name="sort-descending" value={"desc"}>
              Z-A Descending
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: "96px" }} variant="standard">
          <InputLabel id="filter-label" shrink={true}>
            Filter
          </InputLabel>
          <Select
            data-testid="filter"
            data-dd-action-name="filter"
            labelId="filter-label"
            id="filter-select"
            value={filter}
            label="FILTER"
            onChange={(e) => updateHubParams({ filter: e.target.value })}
          >
            <MenuItem value={""}>Clear</MenuItem>
            <MenuItem value={userData.email}>My Missions</MenuItem>
          </Select>
        </FormControl>
      </SearchFilters>
    </div>
  )
}

export default HubSearch
