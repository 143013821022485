import History from "@/features/history/History"
import { useGetMission } from "@/hooks/missionHooks"
import useGetMissionHistoryQuery from "@/hooks/query/mission/useGetMissionHistoryQuery"
import { Box } from "@mui/material"

const MissionHistory = () => {
  const selectedMission = useGetMission()

  const { data: history } = useGetMissionHistoryQuery(selectedMission?.uuid)
  return (
    <Box>
      <History history={history} />
    </Box>
  )
}

export default MissionHistory
