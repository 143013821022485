import { getTableauToken } from "@/framework/utils/api/UsersAPI"
import type { TableauTokenResponse } from "@/framework/utils/api/UsersAPI"
import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"

const useGetTableauTokenQuery = (): UseQueryResult<TableauTokenResponse, Error> => {
  const tableauTokenQuery = useQuery<TableauTokenResponse, Error>({
    queryKey: ["tableau-token"],
    queryFn: getTableauToken,
  })

  return tableauTokenQuery
}

export default useGetTableauTokenQuery
