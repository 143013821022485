import { useGetMission, useGetNextMission } from "@/hooks/missionHooks"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { useContext } from "react"
import { useGetVehicle } from "../../hooks/vehiclesV2Hooks"
import MissionCancel from "./MissionCancel"
import MissionCrew from "./MissionCrew"
import MissionDepartureDetails from "./MissionDepartureDetails"
import MissionFreight from "./MissionFreight"
import MissionManualArrival from "./MissionManualArrival"
import MissionNext from "./MissionNext"
import MissionOverview from "./MissionOverview"
import MissionRoute from "./MissionRoute"

const MissionDetailInfo = ({ activeMission }) => {
  const mission = useGetMission() ?? activeMission

  const vehicle = useGetVehicle()
  const truck_gen = parseFloat(vehicle?.gen)
  const nextMission = useGetNextMission(mission?.uuid) ?? null
  const { hasAnyPermissions } = useContext(UserContext)

  return (
    <Stack spacing={1} sx={{ p: 1 }} divider={<Divider flexItem sx={{ borderBottomWidth: 3 }} />}>
      <Box className="mission-details-planning__content">
        <Typography color="primary">PLANNING</Typography>
        <MissionDepartureDetails mission={mission} showZip={true} />
      </Box>
      <Box className="mission-details-overview__content">
        {truck_gen >= 3.0 ? (
          <MissionOverview mission={mission} />
        ) : (
          <>
            <Typography color="primary">ROUTE</Typography>
            <MissionRoute mission={mission} vehicle={vehicle} />
          </>
        )}
      </Box>
      <Box className="mission-details-crew__content">
        <Typography color="primary">CREW</Typography>
        <MissionCrew departure={mission} />
      </Box>
      <Box className="mission-details-freight__content">
        <Typography color="primary">FREIGHT</Typography>
        <MissionFreight departure={mission} />
      </Box>
      {nextMission?.load_id && (
        <Box className="mission-details-upcoming__content">
          <Typography color="primary">NEXT MISSION</Typography>
          <MissionNext mission={nextMission} />
        </Box>
      )}
      {hasAnyPermissions(["MissionOverride"]) && (
        <Box className="mission-details-additional-actions__content">
          <Typography color="primary">ADDITIONAL ACTIONS</Typography>
          <div className="mission-details-additional-actions__content__button-group">
            <MissionManualArrival mission={mission} />
            <MissionCancel mission={mission} />
          </div>
        </Box>
      )}
    </Stack>
  )
}

export default MissionDetailInfo
