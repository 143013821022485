import { formatOnTimeStatus, formatSecondsToMMorHHMM, getFormattedDate, getOnTimeStyle } from "@/framework/Utils"
import { CircleOutlined } from "@mui/icons-material"
import { Divider, Typography } from "@mui/material"
import { getArrivalLabel, getDepartureDate, getDepartureLabel } from "../../framework/utils/MissionDisplay"
import "./scss/MissionDetails.scss"

function onTimeLabel(mission) {
  if (mission?.state != "ARRIVED" && mission?.state != "COMPLETED" && mission?.schedule_status) {
    return (
      <Typography
        data-testid="on-time-status-id"
        className={`mission-departure-details__status ${getOnTimeStyle(mission?.schedule_status)}`}
      >
        {mission?.schedule_status == "ON_TIME" ? null : formatSecondsToMMorHHMM(mission?.schedule_status_amount)}{" "}
        {formatOnTimeStatus(mission?.schedule_status)}
      </Typography>
    )
  } else {
    return <div></div>
  }
}

function getArrivalTime(mission) {
  if (mission?.estimated_time) {
    return getFormattedDate(mission.estimated_time, "LL/dd hh:mm ZZZZ")
  } else {
    return getFormattedDate(mission?.tms_schedule_arrival_time, "LL/dd hh:mm ZZZZ")
  }
}

const MissionDepartureDetails = ({ mission, showZip }) => (
  <div className="mission-departure-details">
    <div className="mission-departure-details__depart-label">
      <Typography className="mission-departure-details__label">depart</Typography>
    </div>
    <div className="mission-departure-details__arrive-label">
      <Typography className="mission-departure-details__label">arrive</Typography>
    </div>
    <div className="mission-departure-details__divider">
      <Divider orientation="vertical">
        <CircleOutlined className="top" />
        <CircleOutlined className="bottom" />
      </Divider>
    </div>
    <div className="mission-departure-details__depart-city">
      <Typography className="mission-departure-details__city">
        {mission?.start_display} {showZip && mission?.start_zone != "NO_VALUE" ? mission?.start_zone : ""}
      </Typography>
    </div>
    <div className="mission-departure-details__arrive-city">
      <Typography className="mission-departure-details__city">
        {mission?.destination_display}{" "}
        {showZip && mission?.destination_zone != "NO_VALUE" ? mission?.destination_zone : ""}
      </Typography>
    </div>
    <div className="mission-departure-details__depart-date">
      <Typography className="mission-departure-details__label">{getDepartureLabel(mission?.state)}</Typography>
      <Typography data-testid="departure-date" className="mission-departure-details__date">
        {getDepartureDate(mission)}
      </Typography>
    </div>
    <div className="mission-departure-details__arrive-date">
      <Typography className="mission-departure-details__label">{getArrivalLabel(mission?.state)}</Typography>
      <Typography className="mission-departure-details__date">{getArrivalTime(mission)}</Typography>
    </div>

    <Typography className="mission-departure-details__eta">{/** empty for now??? */} </Typography>
    {onTimeLabel(mission)}
  </div>
)

export default MissionDepartureDetails
