import _ from "lodash"

/**
 * Returns the on time Label text
 *
 * @param {String} time
 */
export function getOnTimeText(time) {
  if (!_.isEmpty(time)) {
    if (time.includes("On-Time")) {
      return "On-Time"
    } else {
      let timeleft = time.split(" ")
      return `${formatTimeLeft(timeleft[0])} ${timeleft[1]}`
    }
  } else return ""
}

/**
 * Formates hour and mins
 *
 * @param {String} time
 */
export function formatTimeLeft(time) {
  if (_.isEmpty(time)) {
    return ""
  } else {
    let timeleft = time.split(":")
    let hour = timeleft[0] == "00" ? "" : `${timeleft[0]}h `
    let minutes = `${timeleft[1]}m`
    return `${hour}${minutes}`
  }
}

/**
 * Returns the on time Label text
 *
 * @param {String} time
 */
export function getOnTimeClass(time) {
  if (time && time.includes("On-Time")) {
    return "__onTime"
  } else if (time && time.includes("Delay")) {
    return "__delay"
  } else {
    return ""
  }
}
