import { MissionsApiV2 } from "@/framework/utils/api/MissionsAPI"
import { LastPage } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { toast } from "react-toastify"

const MissionManualArrival = (mission) => {
  const queryClient = useQueryClient()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const disableAllowArrival = ["ARRIVED", "COMPLETED"].includes(mission.mission?.state)

  const mutation = useMutation({
    mutationFn: () => MissionsApiV2.postMissionArrive({ missionUuid: mission.mission?.uuid }),
    onSuccess: (data) => {
      toast.success("Successfully sent mission arrive request", data)
      queryClient.invalidateQueries({ queryKey: ["mission", mission?.uuid] })
    },
  })

  const manualArrival = () => {
    setShowConfirmModal(true)
  }

  const confirmManualArrival = async () => {
    try {
      await mutation.mutateAsync()
    } catch (err) {
      toast.error("Unable to request mission arrival.")
    }
    setShowConfirmModal(false)
  }

  return (
    <Box>
      {showConfirmModal && (
        <Dialog open={showConfirmModal} onClose={() => setShowConfirmModal(false)} data-testid="mission-arrival-dialog">
          <DialogTitle data-testid="mission-arrival-dialog-title">
            Are you sure you want to manually arrive this mission?
          </DialogTitle>
          <DialogActions>
            <Button
              data-testid="confirm-mission-arrival"
              type="submit"
              variant="contained"
              onClick={() => confirmManualArrival()}
            >
              Confirm
            </Button>
            <Button
              data-testid="cancel-mission-arrival"
              type="submit"
              variant="outlined"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        data-testid="open-mission-arrival"
        type="submit"
        variant="contained"
        disabled={disableAllowArrival}
        onClick={() => manualArrival()}
      >
        Arrive Manually <LastPage />
      </Button>
    </Box>
  )
}

export default MissionManualArrival
