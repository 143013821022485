import MissionDetails from "@/features/missions/MissionDetails"
import VehicleList from "@/features/vehicles/VehicleList"
import { tabRoutes } from "@/hooks/detailTabsHelper"
import { Typography } from "@mui/material"
import { type RouteObject } from "react-router-dom"

const VehicleRoutes: RouteObject = {
  path: "vehicles",
  children: [
    { index: true, element: <VehicleList /> },
    {
      path: ":vehicle_id",
      element: <MissionDetails activeMission={null} setMissionUUIDFocus={null} />,
      children: tabRoutes
        .filter((tab) => tab.matchRoute === "vehicles" || tab.matchRoute === "*")
        .map((tab) => ({
          path: tab.pathname,
          element: tab.element,
          errorElement: (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                margin: "1.5rem",
              }}
            >
              <Typography variant="overline">An error occurred while loading this tab.</Typography>
              <Typography variant="overline">
                Please refresh the page and contact developers if the issue persists.
              </Typography>
            </div>
          ),
        })),
    },
  ],
}
export default VehicleRoutes
