/* istanbul ignore file */

import { Box, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { useContext, useState } from "react"
import { FaCheck, FaChevronDown, FaChevronUp, FaLayerGroup, FaMap, FaMapMarkerAlt, FaRoute } from "react-icons/fa"
import "./scss/MapLayers.scss"

export default function MapLayers() {
  const { userData } = useContext(UserContext)
  const [anchorMapMenu, setAnchorMapMenu] = useState(null)
  const [anchorLayersMenu, setAnchorLayersMenu] = useState(null)

  const toggleShowLayer = (layerKey, showLayer) => {
    userData.preferences.mapLayerSelection[layerKey] = !showLayer
  }
  const layersMenu = [
    {
      name: "Truck Paths",
      id: "showTruckPaths",
      icon: <FaRoute />,
      display: userData.preferences.mapLayerSelection.showTruckPaths,
    },
    {
      name: "Coordinates",
      id: "showCoords",
      icon: <FaMapMarkerAlt />,
      display: userData.preferences.mapLayerSelection.showCoords,
    },
  ]
  return (
    <Box
      sx={{
        position: "absolute",
        width: 270,
        top: "85px",
        maxWidth: "100%",
        zIndex: 10,
      }}
    >
      <MenuList className="menu-list" data-testid="map-layers-menu" data-dd-action-name="map-layers-menu">
        <MenuItem
          data-testid="map-menu"
          data-dd-action-name="map-menu"
          className="map-menu-item"
          onClick={() => setAnchorMapMenu(!anchorMapMenu)}
        >
          <FaMap />
          <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Map" />
          <Typography className="menu-arrows">{anchorMapMenu ? <FaChevronUp /> : <FaChevronDown />}</Typography>
        </MenuItem>
        {anchorMapMenu ? (
          <MenuList className="sub-menu-list" sx={{ paddingLeft: "20px" }}>
            <MenuItem
              onClick={() => setAnchorLayersMenu(!anchorLayersMenu)}
              className="menu-item"
              data-testid="layers"
              data-dd-action-name="layers"
            >
              <ListItemIcon className="icon">
                <FaLayerGroup />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Layers" />
              <Typography className="menu-arrows">{anchorLayersMenu ? <FaChevronUp /> : <FaChevronDown />}</Typography>
            </MenuItem>
            {anchorLayersMenu ? (
              <MenuList sx={{ paddingLeft: "25px" }} className="sub-menu-list">
                {layersMenu.map((layer) => {
                  return (
                    <MenuItem
                      key={layer.id}
                      data-testid={layer.id}
                      data-dd-action-name={layer.id}
                      href="#"
                      onClick={() => {
                        toggleShowLayer(layer.id, layer.display)
                      }}
                      className="menu-item"
                    >
                      <ListItemIcon className="icon"> {layer.icon} </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary={layer.name} />
                      {layer.display ? <FaCheck className="check" /> : null}
                    </MenuItem>
                  )
                })}
              </MenuList>
            ) : null}
          </MenuList>
        ) : null}
      </MenuList>
    </Box>
  )
}
